import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import EthicalImg from "../../images/ethical.png";
import CustomerImg from "../../images/customer-focus.png";
import InnovationImg from "../../images/learning-growth.png";
import LearningImg from "../../images/innovation.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-4 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-secondary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {

  const cards = [
    {
      imageSrc: InnovationImg,
      title: "Innovation",
      description: "We believe that the secret to business longevity is innovative and evolving solutions to present and future challenges. Staying ahead of the curve is the lifeline of any business."
    },
    {
      imageSrc: LearningImg,
      title: "Continuous Learning and Growth",
      description: "We encourage our team and our clients to adopt training and learning plans to help keep the team fluid enough to be prepared for the very dynamic kind of business environments we have today."
    },
    {
      imageSrc: CustomerImg,
      title: "Customer Focus",
      description: "At INAN, ‘the customer is always right’…in matters of what they want. We always try to read the room with our focus on the feedback loop mechanisms, customer pulse, and trends, and meeting customer expectations."
    },
    {
      imageSrc: EthicalImg,
      title: "Ethical Compliance",
      description: "We observe a strict compliance to not just our standards, but industry, government, and legal regulations. All while making sure all that red tape does not stop your business from breaking boundaries and achieving success."
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Core <span tw="text-primary-500">Values</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
