import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import HeroImg from "../../images/hero_img.png";
import HandImg from "../../images/handy.png";
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg";

const Header = tw(HeaderBase)`max-w-none border-b border-b-2 pb-6`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-8 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const FeatureList = tw.ul`mt-6 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:translate-y-8`;
const Image = tw.img`max-w-full w-128 mt-4 rounded-t sm:rounded relative z-20`;
const Image2 = tw.img`absolute max-w-full w-128 mt-4 rounded-t sm:rounded z-40 animate-[bounce_10s_infinite_1000ms]`;
const NavLinkStyled = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer`}
`;


const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-2`}
  svg {
    ${tw`w-10 h-10 p-2`}
  }
`;


export default ({
  heading = "Empower Your Business",
  description = "From people to finance, and beyond, we're your trusted partner in navigating the complexities of modern business.",
  imageSrc = HeroImg,
  imageDecoratorBlob = true,
  features = ["Tailored Solutions", "Expert Guidance", "Strategic Management Consulting"],
  
}) => {
  const scrollToSection = (sectionId) => {
    console.log("Scrolling to section:", sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("Section not found:", sectionId);
    }
  };


  const navLinks = [
    <NavLinks key={1}>
      <NavLinkStyled onClick={() => {scrollToSection("solutions");}}> Solutions </NavLinkStyled>
      <NavLinkStyled onClick={() => {scrollToSection("about");}}> About Us </NavLinkStyled>
      <NavLinkStyled onClick={() => {scrollToSection("faq");}}> FAQs </NavLinkStyled>
    </NavLinks>,

    <NavLinks key={2}>
      <NavLinkStyled onClick={() => {scrollToSection("contact");}} tw="lg:ml-12"> Contact</NavLinkStyled>
      <SocialLink href="tel:+2347059992235">
         <PhoneIcon />
      </SocialLink>
      <SocialLink href="mailto:info@inan.com.ng?subject=Mail From Our Website:">
         <EmailIcon />
      </SocialLink>
    
    </NavLinks>
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image2 src={HandImg}/>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
