import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  faqs = [
    {
      question: "What types of businesses do you serve?",
      answer:
        "We cater to a diverse range of industries, including but not limited to retail, hospitality, healthcare, technology, and manufacturing. Our solutions are adaptable to the unique needs of various businesses, regardless of size or sector."
    },
    {
      question: "How do you ensure the security of our data?",
      answer:
        "We prioritize the security and confidentiality of your data through robust encryption protocols, regular security audits, and strict access controls. Rest assured, your information is safeguarded against unauthorized access or breaches."
    },
    {
      question: "Can you customize your services to fit our specific requirements?",
      answer:
        "Absolutely! We understand that every business is unique, and our team is dedicated to tailoring our solutions to align with your specific goals, challenges, and industry requirements. Let us know your needs, and we'll craft a customized plan just for you."
    },
    {
      question: "What sets INAN Management apart from other management service providers?",
      answer:
        "At INAN Management, we distinguish ourselves through our unwavering commitment to excellence, personalized approach, and deep industry expertise. We prioritize client satisfaction and strive to exceed expectations in every aspect of our service delivery."
    },
    {
      question: "How quickly can we expect to see results after implementing your solutions?",
      answer:
        "While the timeline for results may vary depending on factors such as the complexity of your requirements and the scope of services, our goal is to deliver tangible outcomes and measurable improvements as efficiently as possible. We work collaboratively with you to achieve timely and impactful results."
    },
    {
      question: "What level of support do you provide after implementation?",
      answer:
        "Our support doesn't end with the implementation of our solutions. We offer ongoing assistance, monitoring, and maintenance to ensure the continued success and optimization of our services. Whether you have questions, encounter challenges, or seek further enhancements, our team is here to support you every step of the way.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer id="faq">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
